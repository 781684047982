import React, { useCallback, useState, useEffect, useRef } from "react";
import { ChevronDown } from "lucide-react";
import "./PageMask.css";

const PageMask = ({
  curtainHeight,
  activeTool,
  containerRef,
  updateCurtainHeight,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const lastY = useRef(null);
  const animationFrameRef = useRef(null);

  const handleMouseDown = (e) => {
    if (e.target.closest(".pull-cord")) {
      setIsDragging(true);
      lastY.current = e.clientY;
      e.preventDefault();
    }
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging && containerRef.current) {
        // Cancel any existing animation frame
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }

        // Schedule the update on the next animation frame
        animationFrameRef.current = requestAnimationFrame(() => {
          const containerRect = containerRef.current.getBoundingClientRect();
          const deltaY = e.clientY - lastY.current;
          const newHeight = Math.max(
            20,
            Math.min(curtainHeight + deltaY, containerRect.height)
          );

          updateCurtainHeight(newHeight);
          lastY.current = e.clientY;
        });
      }
    },
    [isDragging, containerRef, updateCurtainHeight, curtainHeight]
  );

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      lastY.current = null;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    }
  };

  // Clean up animation frame on unmount
  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  // Add global event listeners when dragging starts
  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [isDragging, handleMouseMove]);

  // Show curtain if height > 20 OR (height === 20 AND activeTool === 'page-mask')
  const shouldShowCurtain =
    curtainHeight > 20 || (curtainHeight <= 20 && activeTool === "page-mask");

  if (!shouldShowCurtain) {
    return null;
  }

  return (
    <div
      className={`page-mask-curtain ${isDragging ? "dragging" : ""}`}
      style={{
        height: `${curtainHeight}px`,
        cursor: activeTool === "page-mask" ? "ns-resize" : "default",
      }}
    >
      {activeTool === "page-mask" && (
        <div className="pull-cord" onMouseDown={handleMouseDown}>
          <ChevronDown size={16} color="white" className="icon" />
        </div>
      )}
    </div>
  );
};

export default PageMask;
