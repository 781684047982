import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress"; //Progress bar
import Snackbar from "@mui/material/Snackbar";

import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import DrawingLayer from "./DrawingLayer";
import "./PDFViewer.css"; // Import your CSS file
import SearchComponent from './SearchComponent';
import VersionDropdown from "./VersionDropdown";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFViewer = ({ pdfURL, propPageNumber, mode, activeTool, bookId }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [viewMode, setViewMode] = useState("single"); // Dropdown state for viewing mode
  const [scale, setScale] = useState(1);
  const [isCached, setIsCached] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const pdfContainerRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [annotations, setAnnotations] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]); // For search results
  const [currentMatch, setCurrentMatch] = useState(0); // For current match index
  const [totalMatches, setTotalMatches] = useState(0); // Total matches found
  const [highlightedMatches, setHighlightedMatches] = useState([]);
  const [encodedPdfURL, setEncodedPdfURL] = useState(null);
  const [currentResultIndex, setCurrentResultIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Handle annotation update from DrawingLayer
  const handleUpdateAnnotations = (newAnnotations) => {
    setAnnotations(newAnnotations);
  };
  // Load version from VersionDropdown
  const handleLoadVersion = (versionAnnotations) => {
    setAnnotations(versionAnnotations); // Update with loaded version
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu
  };

  useEffect(() => {
    // Base64 encode the original URL
    const encodedUrl = btoa(pdfURL?.replace(
      "https://redsea-books-assets.s3.eu-central-1.amazonaws.com",
      "https://dng2cduv9v2yj.cloudfront.net"
  ));
    setEncodedPdfURL(encodedUrl);
  }, [pdfURL]);

  const decodePdfUrl = (encodedUrl) => {
    // Decode the Base64 encoded URL
    return atob(encodedUrl);
  };

  useEffect(() => {
    const checkCachedPDF = async () => {
      const cache = await caches.open("appV1");
      const cachedResponse = await cache.match(pdfURL);
      setIsCached(!!cachedResponse);

      // Check localStorage to see if PDF is cached
      const cachedState = localStorage.getItem("pdfCached");
      if (cachedState === "true") {
        setIsCached(true);
      }
    };

    checkCachedPDF();

    if (propPageNumber) {
      setPageNumber(propPageNumber);
    }
  }, [isCached]);

  // Check cached assets
  const cachePDF = async () => {
    setIsDownloading(true); // Show progress bar
    console.log("Download started...");
    const response = await fetch(pdfURL);
    const reader = response.body.getReader();
    const contentLength = +response.headers.get("Content-Length"); // Get the total size of the file

    let receivedLength = 0; // Amount of data downloaded so far
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      chunks.push(value);
      receivedLength += value.length;

      // Update the progress
      const progressPercentage = Math.floor(
        (receivedLength / contentLength) * 100
      );
      console.log(`Progress: ${progressPercentage}%`);
      setProgress(progressPercentage);
    }

    const cache = await caches.open("appV1");
    const blob = new Blob(chunks); // Create a Blob from the chunks
    const responseBlob = new Response(blob);
    await cache.put(pdfURL, responseBlob); // Cache the downloaded PDF

    setIsCached(true); // Update state after caching
    setIsDownloading(false); // Hide progress bar after download
    console.log("Download completed");

    // Show the snackbar after download
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAudioPlay = (voiceFileName) => {
    const audioElements = document.getElementsByTagName("audio");
    for (let i = 0; i < audioElements.length; i++) {
      const audioElement = audioElements[i];
      if (voiceFileName === audioElement.src) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    }
  };
  const onNextPage = () => {
    const pagesToSkip =
      viewMode === "single" ? 1 : viewMode === "two-page" ? 2 : 3;
    if (pageNumber + pagesToSkip <= numPages) {
      setPageNumber(pageNumber + pagesToSkip);
    }
  };
  const onPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const onPageNumberChange = (e) => {
    const value = e.target.value;
    const pageNum = parseInt(value, 10);

    if (value === "" || (pageNum > 0 && pageNum <= numPages)) {
      setPageNumber(pageNum);
    }
  };

  // Handle the view mode change (dropdown)
  const handleViewModeChange = (e) => {
    setViewMode(e.target.value);
  };

  // Full screen PDF
  const toggleFullscreen = () => {
    if (isFullscreen) {
      document.exitFullscreen();
      document.querySelector(".react-pdf__Document").style.marginTop = "0";
    } else if (pdfContainerRef.current) {
      pdfContainerRef.current.requestFullscreen();
      document.querySelector(".react-pdf__Document").style.marginTop = "40px";
    }
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") onNextPage();
      if (event.key === "ArrowLeft") onPrevPage();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [pageNumber]);


  
    // Zoom (in & out feature)
    const zoomIn = () => {
      setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level of 3
    };
    const zoomOut = () => {
      setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom level of 0.5
    };
    const resetZoom = () => {
      setScale(1); // Default zoom level is 1
    };

  const handleSearch = async (term) => {
    setHighlightedMatches([]); // Clear previous highlights
    const pdf = await pdfjs.getDocument(pdfURL).promise;
    const matches = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const textItems = content.items;
      const regex = new RegExp(term, 'gi');

    for (const item of textItems) {
      // Find all matches of the term within the text content
      let match;
      while ((match = regex.exec(item.str)) !== null) {
        // Calculate the position of the matched word
        const matchStart = match.index;
        const matchLength = match[0].length;

        // Approximate width of the matched word based on the proportion of characters in the whole item
        const wordWidth = (item.width / item.str.length) * matchLength;

        // Approximate X position of the matched word within the text item
        const wordX = item.transform[4] + (item.width / item.str.length) * matchStart;

        matches.push({
          page: i,
          item: {
            ...item,
            str: match[0], // Store the exact matched word
            width: wordWidth,
            transform: [
              ...item.transform.slice(0, 4), // Keep the existing scale/rotation values
              wordX, // Adjust the X position
              item.transform[5], // Y position remains the same
            ],
          },
        });
      }
    }
    }

    setSearchResults(matches);
    setTotalMatches(matches.length);
    setCurrentMatch(1);
    highlightMatches(matches);
    setHighlightedMatches(matches);
    
    // Highlight matches only if there are any
    if (matches.length > 0) {
      highlightMatches(matches);
    }
  };

  // Function to highlight matches in the PDF
  const highlightMatches = (matches) => {
    setHighlightedMatches(matches);
  };

  const onNextResult = () => {
    const matchesOnPage = highlightedMatches.filter(match => match.page === pageNumber);
    
    // If the current match index is less than the number of matches on this page
    if (currentMatch < matchesOnPage.length) {
      setCurrentMatch(currentMatch + 1); // Move to the next match
    } else {
      // Check if there's a next page
      const nextPage = pageNumber + 1;
      const nextPageMatches = highlightedMatches.filter(match => match.page === nextPage);
  
      if (nextPageMatches.length > 0) {
        // If there's a next page with matches, navigate to it
        setPageNumber(nextPage);
        setCurrentMatch(1); // Reset to the first match on the new page
      } else {
        // Wrap around to the first match on the first page (if you want to cycle through)
        setPageNumber(1);
        const firstMatch = highlightedMatches.find(match => match.page === 1);
        if (firstMatch) {
          setCurrentMatch(1);
          setPageNumber(firstMatch.page);
        }
      }
    }
  };
  
  const onPrevResult = () => {
    const matchesOnPage = highlightedMatches.filter(match => match.page === pageNumber);
    
    if (currentMatch > 1) {
      setCurrentMatch(currentMatch - 1); // Move to the previous match
    } else {
      // Check if there's a previous page
      const prevPage = pageNumber - 1;
      const prevPageMatches = highlightedMatches.filter(match => match.page === prevPage);
  
      if (prevPageMatches.length > 0) {
        // If there's a previous page with matches, navigate to it
        setPageNumber(prevPage);
        setCurrentMatch(prevPageMatches.length); // Reset to the last match on the new page
      } else {
        // Optionally wrap to the last page
        const lastPage = Math.max(...highlightedMatches.map(match => match.page)); // Get the last page number
        const lastPageMatches = highlightedMatches.filter(match => match.page === lastPage);
        if (lastPageMatches.length > 0) {
          setPageNumber(lastPage);
          setCurrentMatch(lastPageMatches.length); // Set to the last match index
        }
      }
    }
  };
  
  const renderPages = () => {
    const filteredMatches = highlightedMatches.filter(match => match.page === pageNumber);
    switch (viewMode) {
      case "single":
        return (
          <div className="pdf-single-page">
            <div style={{ position: "relative" }}>
              <Page pageNumber={pageNumber} renderMode="canvas" scale={scale} />

              {filteredMatches.map((match, index) => {
                const { transform } = match.item;
                console.log('Match', match);
                if (!transform || transform.length < 4) {
                  return null; // Avoid rendering if transform is invalid
                }

                const [x, y] = [transform[4], transform[5]];
                const width = match.item.width;
                const height = match.item.height;

                // Adjust for potential coordinate origin differences
                const viewerHeight = document.querySelector('.pdf-pages').clientHeight; // Get viewer height
                const adjustedY = viewerHeight - y - height; // Invert Y if necessary

                // Determine if this match is the current match
                const isCurrentMatch = currentMatch === index + 1; // Check if this is the current match index

                return (
                  <div
                    key={index}
                    className="highlight"
                    style={{
                      position: "absolute",
                      left: x,
                      top: adjustedY,
                      width: width,
                      height: height,
                      backgroundColor: isCurrentMatch ? "rgba(255, 0, 0, 0.5)" : "rgba(255, 255, 0, 0.5)",
                      pointerEvents: "none",
                    }}
                  />
                );
              })}
              {activeTool !== null && (
                <DrawingLayer
                  activeTool={activeTool}
                  pageNumber={pageNumber}
                  annotations={annotations}
                  onUpdateAnnotations={handleUpdateAnnotations}
                />
              )}
            </div>
          </div>
        );
      case "double":
        return (
          <div className="pdf-double-page">
            <div style={{ position: "relative" }}>
              <Page pageNumber={pageNumber} renderMode="canvas" scale={scale} />
              {activeTool !== null && (
                <DrawingLayer
                  activeTool={activeTool}
                  pageNumber={pageNumber}
                  annotations={annotations}
                  onUpdateAnnotations={handleUpdateAnnotations}
                />
              )}
            </div>
            {pageNumber < numPages && (
              <div style={{ position: "relative" }}>
                <Page
                  pageNumber={pageNumber + 1}
                  renderMode="canvas"
                  scale={scale}
                />
                {activeTool !== null && (
                  <DrawingLayer
                    activeTool={activeTool}
                    pageNumber={pageNumber + 1}
                    annotations={annotations}
                    onUpdateAnnotations={handleUpdateAnnotations}
                  />
                )}
              </div>
            )}
          </div>
        );
      case "nextDouble":
        return (
          <div className="pdf-double-page">
            {pageNumber + 1 <= numPages && (
              <div style={{ position: "relative" }}>
                <Page
                  pageNumber={pageNumber + 1}
                  renderMode="canvas"
                  scale={scale}
                />
                {activeTool !== null && (
                  <DrawingLayer
                    activeTool={activeTool}
                    pageNumber={pageNumber + 1}
                    annotations={annotations}
                    onUpdateAnnotations={handleUpdateAnnotations}
                  />
                )}
              </div>
            )}
            {pageNumber + 2 <= numPages && (
              <div style={{ position: "relative" }}>
                <Page
                  pageNumber={pageNumber + 2}
                  renderMode="canvas"
                  scale={scale}
                />
                {activeTool !== null && (
                  <DrawingLayer
                    activeTool={activeTool}
                    pageNumber={pageNumber + 2}
                    annotations={annotations}
                    onUpdateAnnotations={handleUpdateAnnotations}
                  />
                )}
              </div>
            )}
          </div>
        );
      default:
        return (
          <div className="pdf-single-page">
            <div style={{ position: "relative" }}>
              <Page pageNumber={pageNumber} renderMode="canvas" scale={scale} />
              {highlightedMatches.filter(match => match.page === pageNumber).map((match, index) => {
                console.log('Match', match);
                const { transform } = match.item;
  
                if (!transform || transform.length < 4) {
                  return null; // Avoid rendering if transform is invalid
                }
                const yAdjustment = index * 2;

                const [x, y] = [transform[4] , transform[5] ];
                const width = match.item.width;
                const height = match.item.height;
                console.log(`Highlight position - X: ${x}, Y: ${y}, Width: ${width}, Height: ${height}`);
                return (
                  <div
                    key={index}
                    className="highlight"
                    style={{
                      position: "absolute",
                      left: x,
                      top: y,
                      width: width,
                      height: height,
                      backgroundColor: "rgba(255, 255, 0, 0.5)",
                      pointerEvents: "none",
                    }}
                  />
                );
              })}
              {activeTool !== null && (
                <DrawingLayer
                  activeTool={activeTool}
                  pageNumber={pageNumber}
                  annotations={annotations}
                  onUpdateAnnotations={handleUpdateAnnotations}
                />
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div
      onContextMenu={handleContextMenu}
      className="pdf-container"
      style={{ overflowX: mode == "digital" ? "scroll" : "visible" }}
      ref={pdfContainerRef}
    >
      


      {/* Display Progress Bar */}
      {isDownloading && (
        <div className="progress-bar-container">
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ width: "100%", height: "10px", marginBottom: "10px" }}
          />
          <span className="progress-text">{progress}%</span>
        </div>
      )}

      {/* Snackbar for download notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          PDF cached successfully!
        </Alert>
      </Snackbar>
      {/* Version Dropdown */}
      <VersionDropdown
        userId={localStorage.getItem("id")}
        bookId={bookId}
        onLoadVersion={handleLoadVersion}
        annotations={annotations}
      />
      <div className="page-info">
        <SearchComponent
            onSearch={handleSearch}
            onNextResult={onNextResult}
            onPrevResult={onPrevResult}
            totalMatches={totalMatches}
            currentMatch={currentMatch}
            setSearchTerm={setSearchTerm}
        />
        <Tooltip title="Zoom In">
          <ZoomInIcon onClick={zoomIn} />
        </Tooltip>

        <Tooltip title="Reset Zoom">
          <RestartAltIcon onClick={resetZoom} />
        </Tooltip>

        <Tooltip title="Zoom Out">
          <ZoomOutIcon onClick={zoomOut} />
        </Tooltip>
        <Tooltip title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}>
          {isFullscreen ? (
            <FullscreenExitIcon
              onClick={toggleFullscreen}
              className="fullscreen-icon"
            />
          ) : (
            <FullscreenIcon
              onClick={toggleFullscreen}
              className="fullscreen-icon"
            />
          )}
        </Tooltip>
        <Tooltip
          title={
            isCached ? "Book is available for offline use" : "Download Book"
          }
        >
          {isCached ? (
            <OfflinePinIcon />
          ) : (
            <CloudDownloadIcon onClick={cachePDF} />
          )}
        </Tooltip>
        {mode == "digital" && (
          <>
            <label htmlFor="viewMode">View Mode: </label>
            <select
              id="viewMode"
              value={viewMode}
              onChange={handleViewModeChange}
            >
              <option value="single">{`Page ${pageNumber}`}</option>
              <option value="double">{`Page ${pageNumber}-${
                pageNumber + 1 > numPages ? numPages : pageNumber + 1
              }`}</option>
              <option value="nextDouble">{`Page ${pageNumber + 1}-${
                pageNumber + 2 > numPages ? numPages : pageNumber + 2
              }`}</option>
            </select>
          </>
        )}
        <span style={{ marginLeft: "10px" }}>
          <button
            className="prev-button"
            onClick={onPrevPage}
            disabled={pageNumber <= 1}
          >
            &#x2039; {/* Left arrow */}
          </button>
          Page{" "}
          <input
            type="number"
            value={pageNumber}
            onChange={onPageNumberChange}
            min="1"
            max={numPages}
            className="page-number-input"
          />{" "}
          of {numPages}
          <button
            className="next-button"
            onClick={onNextPage}
            disabled={pageNumber >= numPages}
          >
            &#x203A; {/* Right arrow */}
          </button>
        </span>
      </div>
      <Document
        file={decodePdfUrl(encodedPdfURL)}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        <div className="pdf-pages">{renderPages()}</div>
      </Document>
      <div className="pdf-navigation"></div>
    </div>
  );
};

export default PDFViewer;
