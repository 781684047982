import React, { useState, useCallback, memo, useEffect } from "react";
import { X, Trash2, Eye, EyeOff } from "lucide-react";

const SelectionMask = memo(
  ({ selection, onDelete, isSelected, isVisible, onToggleVisibility }) => {
    return (
      <div
        style={{
          position: "absolute",
          left: selection.x,
          top: selection.y,
          width: selection.width,
          height: selection.height,
          backgroundColor: `rgba(139, 111, 36, ${isVisible ? 1 : 0.3})`,
          border: `2px solid ${
            isSelected ? "#8b6f24" : "rgba(139, 111, 0, 0.5)"
          }`,
          cursor: "pointer",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {isSelected && (
          <div
            className="selection-controls"
            style={{
              position: "absolute",
              top: "-36px",
              right: "-8px",
              display: "flex",
              gap: "4px",
              backgroundColor: "#8b6f24",
              padding: "4px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
            }}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                onToggleVisibility();
              }}
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: "#8b6f24",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                padding: 0,
              }}
              className="hover:bg-[#725a1d] transition-colors"
            >
              {isVisible ? (
                <EyeOff size={16} color="white" />
              ) : (
                <Eye size={16} color="white" />
              )}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: "#dc2626",
                border: "none",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                padding: 0,
              }}
              className="hover:bg-red-700 transition-colors"
            >
              <Trash2 size={16} color="white" />
            </button>
          </div>
        )}
      </div>
    );
  }
);

const SelectionMaskTool = ({
  activeTool,
  selectionMasks = [],
  onSelectionChange,
  onSelectionEdit,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentSelection, setCurrentSelection] = useState(null);
  const [selectedMaskId, setSelectedMaskId] = useState(null);
  const [visibilityState, setVisibilityState] = useState({});

  const handleMouseDown = useCallback(
    (e) => {
      if (activeTool !== "selection-mask") return;
      if (!e.target.classList.contains("selection-mask-tool")) return;

      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      setIsDrawing(true);
      setStartPos({ x, y });
      setCurrentSelection({
        x,
        y,
        width: 0,
        height: 0,
      });
      setSelectedMaskId(null);
    },
    [activeTool]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDrawing) return;

      const rect = e.currentTarget.getBoundingClientRect();
      const currentX = e.clientX - rect.left;
      const currentY = e.clientY - rect.top;

      const width = currentX - startPos.x;
      const height = currentY - startPos.y;

      setCurrentSelection({
        x: width < 0 ? currentX : startPos.x,
        y: height < 0 ? currentY : startPos.y,
        width: Math.abs(width),
        height: Math.abs(height),
      });
    },
    [isDrawing, startPos]
  );

  const handleMouseUp = useCallback(() => {
    if (!isDrawing || !currentSelection) return;

    const newMask = {
      ...currentSelection,
      id: Date.now().toString(),
    };

    // Set default visibility for new mask
    setVisibilityState((prev) => ({
      ...prev,
      [newMask.id]: true,
    }));

    onSelectionChange("selection-mask", [...selectionMasks, newMask]);
    setIsDrawing(false);
    setCurrentSelection(null);
    setSelectedMaskId(newMask.id);
  }, [isDrawing, currentSelection, selectionMasks, onSelectionChange]);

  const handleMaskClick = useCallback(
    (e, maskId) => {
      e.stopPropagation();
      // Allow selection when tool is either "selection-mask" or "select"
      if (activeTool === "selection-mask" || activeTool === "select") {
        setSelectedMaskId(selectedMaskId === maskId ? null : maskId);
      }
    },
    [activeTool, selectedMaskId]
  );

  const handleMaskDelete = useCallback(
    (maskId) => {
      const updatedMasks = selectionMasks.filter((mask) => mask.id !== maskId);
      // Clean up visibility state when deleting a mask
      setVisibilityState((prev) => {
        const newState = { ...prev };
        delete newState[maskId];
        return newState;
      });
      onSelectionEdit("selection-mask", updatedMasks);
      setSelectedMaskId(null);
    },
    [selectionMasks, onSelectionEdit]
  );

  const handleToggleVisibility = useCallback((maskId) => {
    setVisibilityState((prev) => ({
      ...prev,
      [maskId]: !prev[maskId],
    }));
  }, []);

  // Initialize visibility state for any masks that don't have it
  useEffect(() => {
    const newVisibilityState = { ...visibilityState };
    let hasChanges = false;

    selectionMasks.forEach((mask) => {
      if (visibilityState[mask.id] === undefined) {
        newVisibilityState[mask.id] = true;
        hasChanges = true;
      }
    });

    if (hasChanges) {
      setVisibilityState(newVisibilityState);
    }
  }, [selectionMasks]);

  // Reset selected mask when changing tools to something other than selection-mask or select
  useEffect(() => {
    if (activeTool !== "selection-mask" && activeTool !== "select") {
      setSelectedMaskId(null);
    }
  }, [activeTool]);

  return (
    <div
      className="selection-mask-tool"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: activeTool === "selection-mask" ? "auto" : "none",
        cursor: isDrawing ? "crosshair" : "default",
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {selectionMasks.map((mask) => (
        <div
          key={mask.id}
          onClick={(e) => handleMaskClick(e, mask.id)}
          style={{
            cursor: "pointer",
            pointerEvents:
              activeTool === "selection-mask" || activeTool === "select"
                ? "auto"
                : "none",
          }}
        >
          <SelectionMask
            selection={mask}
            isSelected={selectedMaskId === mask.id}
            onDelete={() => handleMaskDelete(mask.id)}
            isVisible={visibilityState[mask.id] ?? true}
            onToggleVisibility={() => handleToggleVisibility(mask.id)}
          />
        </div>
      ))}
      {currentSelection && activeTool === "selection-mask" && (
        <div
          style={{
            position: "absolute",
            left: currentSelection.x,
            top: currentSelection.y,
            width: currentSelection.width,
            height: currentSelection.height,
            backgroundColor: "rgba(139, 111, 36, 0.3)",
            border: "2px dashed #8b6f24",
          }}
        />
      )}
    </div>
  );
};

export default memo(SelectionMaskTool);
