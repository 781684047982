import React, { useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import DrawIcon from "@mui/icons-material/Draw";
import IconButton from "@mui/material/IconButton";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RollerShadesIcon from "@mui/icons-material/RollerShades";
import { ReactComponent as EraserIcon } from "../../images/eraserIcon.svg";
import Stack from "@mui/material/Stack";

import "./ActionBar.css";

function ActionBar({ setActiveTool }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleActionBar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`action-bar-container ${isOpen ? "open" : "closed"}`}>
      <IconButton
        className="toggle-button"
        onClick={toggleActionBar}
        sx={{ color: "White", backgroundColor: "#8b6f24" }}
      >
        {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>

      <Stack
        className="ActionBar"
        direction="column"
        spacing={2}
        sx={{ alignItems: "center" }}
      >
        <IconButton
          title="select"
          size="large"
          sx={{ color: "White" }}
          onClick={() => setActiveTool("select")}
        >
          <TouchAppIcon fontSize="large" />
        </IconButton>
        <IconButton
          title="Pen"
          aria-label="Pen tool"
          size="large"
          sx={{ color: "White" }}
          onClick={() => setActiveTool("pen")}
        >
          <DrawIcon fontSize="large" />
        </IconButton>
        <IconButton
          title="Marker"
          size="large"
          sx={{ color: "White" }}
          onClick={() => setActiveTool("marker")}
        >
          <BorderColorIcon fontSize="large" />
        </IconButton>
        <IconButton
          title="Area Mask"
          size="large"
          sx={{ color: "White" }}
          onClick={() => setActiveTool("selection-mask")}
        >
          <HighlightAltIcon fontSize="large" />
        </IconButton>
        <IconButton
          title="Comment"
          size="large"
          sx={{ color: "White" }}
          onClick={() => setActiveTool("comment")}
        >
          <ChatBubbleIcon fontSize="large" />
        </IconButton>
        <IconButton
          title="Text"
          size="large"
          sx={{ color: "White" }}
          onClick={() => setActiveTool("text")}
        >
          <TextFieldsIcon fontSize="large" />
        </IconButton>
        <IconButton
          title="Page Curtain"
          size="large"
          sx={{ color: "White" }}
          onClick={() => setActiveTool("page-mask")}
        >
          <RollerShadesIcon fontSize="large" />
        </IconButton>

        <IconButton
          title="Eraser"
          size="large"
          sx={{ color: "White" }}
          onClick={() => setActiveTool("eraser")}
        >
          <EraserIcon style={{ width: "30px", height: "30px" }} />
        </IconButton>
      </Stack>
    </div>
  );
}

export default ActionBar;
